import React from "react";
import "../projects/project.css"; 
import CardImage from "../../images/cardimg.jpg"; 

function Projects() {
  const projects = [
    {
      title: "Real-Time Chat Room",
      description: "A real-time chat application using React and Socket.io for seamless communication.",
      liveLink: "https://your-live-link.com",
      githubLink: "https://github.com/your-repo",
      image: CardImage,
    },
    {
      title: "COVID-19 Vaccine Slot Checker",
      description: "An app to check available vaccine slots using API integration with real-time data.",
      liveLink: "https://your-live-link.com",
      githubLink: "https://github.com/your-repo",
      image: CardImage,
    },
    // Add more projects here
  ];

  return (
    <div className="projects-section">
      <h3>Projects</h3>
      <div className="projects-content">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <div className="project-image">
              <img src={project.image} alt={project.title} />
            </div>
            <div className="project-info">
              <p>{project.description}</p>
              <div className="project-links">
                <a href={project.liveLink} target="_blank" rel="noopener noreferrer">Live Demo</a>
                <a href={project.githubLink} target="_blank" rel="noopener noreferrer">GitHub</a>
                <span className="heart-icon">❤️</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
