import React from "react";
import "../About/About.css";

function Skills() {
  return (
    <div className="skills-section">
      <h3>Skills</h3>
      <div className="skills-content">
        <div className="skill-category">
          <h4>Programming Languages</h4>
          <ul>
            <li>JavaScript</li>
            <li>Python</li>
            <li>Java</li>
            <li>C++</li>
            <li>C</li>
          </ul>
        </div>

        <div className="skill-category">
          <h4>Web Technologies</h4>
          <ul>
            <li>React.js</li>
            <li>Node.js</li>
            <li>Express.js</li>
            <li>HTML5</li>
            <li>CSS3 / SASS</li>
          </ul>
        </div>

        <div className="skill-category">
          <h4>Databases & Tools</h4>
          <ul>
            <li>MongoDB</li>
            <li>SQL</li>
            <li>Git</li>
            <li>Redux</li>
            <li>Socket.io</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Skills;
