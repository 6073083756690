import React from "react";
import "../Resume/Resume.css"

function Resume() {
  return (
    <div className="body">
      <div class="container">
        <div class="header">
          <h1>Harsh Mishra</h1>
          <p>
            📞 +917089340506 | 📧{" "}
            <a href="mailto:harshmishra9713@gmail.com">
              harshmishra9713@gmail.com
            </a>
          </p>
          <p>
            <a href="https://linkedin.com/in/m-harsh">
              linkedin.com/in/m-harsh
            </a>{" "}
            |{" "}
            <a href="https://harshscript.online">
              harshscript.online
            </a>
          </p>
        </div>

        <section class="section">
          <h2>Education</h2>
          <div class="education">
            <h3>Vellore Institute Of Technology, Chennai (2023-2025)</h3>
            <p>
              Master of Computer Application (GPA: 8.42 / 10.00) - Tamil Nadu,
              India
            </p>
            <ul>
              <li>
                Relevant Coursework: Data Structures and Algorithms (C++),
                Probability & Statistics, Database Management, Machine Learning,
                Cloud Computing, Soft Skills
              </li>
            </ul>

            <h3>Nachiketa College Of Computer Science, Jabalpur (2020-2023)</h3>
            <p>
              Bachelor of Computer Application (Percentage: 69.45) - Madhya
              Pradesh, India
            </p>
            <ul>
              <li>
                Relevant Coursework: Data Structures and Algorithms (C), Cyber
                Security, Java, Python, Networking
              </li>
            </ul>
          </div>
        </section>

        <section class="section">
          <h2>Experience</h2>
          <div class="experience">
            <h3>Front-End Developer | Rablo.in (July 2024 – Present)</h3>
            <p>Remote</p>
            <ul>
              <li>
                Developed and implemented web application modules using
                JavaScript, React, and Next.js, enhancing functionality and user
                experience.
              </li>
              <li>
                Integrated third-party APIs and managed application state with
                Redux for improved data handling and performance.
              </li>
              <li>
                Used Sass for advanced styling and collaborated with design
                teams via Figma.
              </li>
            </ul>

            <h3>
              Web Developer | INTOLO INDIA PRIVATE LIMITED (Nov 2022 – Feb 2023)
            </h3>
            <p>Madhya Pradesh, India</p>
            <ul>
              <li>
                Developed a Library Management System using HTML, CSS,
                JavaScript, and PHP, streamlining book cataloging and user
                management.
              </li>
              <li>
                Integrated an online library book store feature, improving
                accessibility and user experience.
              </li>
            </ul>
          </div>
        </section>

        <section class="section">
          <h2>Projects</h2>
          <div class="projects">
            <h3>COVID-19 Vaccine Slot Availability Checker</h3>
            <p>Technologies: HTML/CSS, Python, API Integration</p>
            <ul>
              <li>
                Developed a user-friendly interface for checking vaccine slot
                availability, leveraging the APISetu API for real-time data.
              </li>
            </ul>

            <h3>Real-Time Chat Room</h3>
            <p>Technologies: HTML, CSS, JS, Socket.io</p>
            <ul>
              <li>
                Implemented real-time messaging with WebSockets and developed a
                responsive interface using React.js.
              </li>
              <li>
                Integrated secure user authentication with JWT and bcrypt.
              </li>
              <li>
                URL:{" "}
                <a href="https://github.com/HARSHMISHRA0408/real-time-chat-room">
                  GitHub Project
                </a>
              </li>
            </ul>

            <h3>Used Car Price Prediction</h3>
            <p>Technologies: Python, Machine Learning</p>
            <ul>
              <li>
                Built a machine learning model for used car price prediction,
                optimizing it through data preprocessing.
              </li>
            </ul>
          </div>
        </section>

        <section class="section">
          <h2>Technical Skills</h2>
          <p>
            <strong>Languages:</strong> Python, C, C++, C#, JavaScript, Java
          </p>
          <p>
            <strong>Databases:</strong> SQL, MongoDB, Azure Databases
          </p>
          <p>
            <strong>Tools & Frameworks:</strong> NodeJS, ReactJS, Scikit-Learn,
            Git, WordPress, Socket.io, Express
          </p>
        </section>

        <section class="section">
          <h2>Certifications</h2>
          <p>
            <strong>Python Certification</strong> - Great Learning, August 2023
          </p>
          <p>
            <strong>SQL Certification</strong> - SKILLUP (Simplilearn), January
            2024
          </p>
        </section>
      </div>
      <div className="downloadbtn"> <a href="https://drive.google.com/file/d/1pkyboUOUb7UKuhqMlsoojJDLnO9E6OQi/view?usp=drive_link">Download Resume</a></div>
    </div>
    
  );
}

export default Resume;
