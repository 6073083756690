import React from "react";
import "../About/About.css";
import pro from "../../images/download.png";

function About() {
  return (
    <div className="mainabout">
      <div className="imagediv">
        <img
          src={pro}
          alt="profile"
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="aboutdiv">
        <h3> About Me</h3>
        <p>
          Hi, I'm <strong>Harsh Mishra</strong>, a passionate and driven Full-Stack Developer
          specializing in <strong>MERN</strong> and <strong>Java</strong> technologies. I am currently pursuing my
          <strong> Master of Computer Application (MCA)</strong> at <strong>Vellore Institute of
          Technology, Chennai</strong>, where I'm building a strong foundation in <strong>data
          structures</strong>, <strong>algorithms</strong>, and emerging technologies like <strong>machine
          learning</strong> and <strong>cloud computing</strong>.
        </p>
        <p>
          I enjoy creating dynamic, responsive, and scalable web applications. With expertise in <strong>React</strong>,
          <strong> Node.js</strong>, <strong>MongoDB</strong>, and <strong>Express</strong>, I have experience building full-stack
          applications that are both efficient and user-friendly. I've also worked with <strong>Java</strong> in
          various backend implementations, ensuring robust and secure services.
        </p>
        <p>
          In my role at <strong>Rablo.in</strong> as a Full-Stack Developer, I enhanced web applications by
          integrating third-party APIs, managing application state with <strong>Redux</strong>, and utilizing modern tools like <strong>Next.js</strong>.
          This resulted in improved user experience and performance across the platform.
        </p>
        <p>
          During my time at <strong>INTOLO INDIA PRIVATE LIMITED</strong>, I developed a Library Management System
          from the ground up using <strong>HTML</strong>, <strong>CSS</strong>, <strong>JavaScript</strong>, and <strong>PHP</strong>, streamlining operations and improving user access
          to resources. I’ve also contributed to impactful projects, such as a <strong>COVID-19 Vaccine Slot Availability Checker</strong> and a <strong>Real-Time Chat Room</strong>,
          which helped me deepen my knowledge of APIs, WebSockets, and user authentication mechanisms.
        </p>
        <p>
          I’m always eager to explore new technologies, take on exciting challenges, and deliver meaningful web solutions that make a difference.
          Whether it's developing full-stack applications or collaborating with cross-functional teams, I strive to create web experiences that are
          not only functional but also impactful.
        </p>
      </div>
    </div>
  );
}

export default About;
