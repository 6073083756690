import React, { useState } from "react";
import "../Home/Home.css";
import centimg from "../../images/center.png";
import upleft from "../../images/upleft.png";
import upright from "../../images/upright.png";
import downleft from "../../images/downleft.png";
import downright from "../../images/downright.png";
import { Link, Navigate, useNavigate } from "react-router-dom";

function Home() {
  const [currentimage, setimage] = useState(centimg);
  const Navigate = useNavigate();

  const handleMouseLeave = () => {
    setimage(centimg);
  };

  const handleupleft = () => {
    setimage(upleft);
  };
  const handleupright = () => {
    setimage(upright);
  };
  const handledownleft = () => {
    setimage(downleft);
  };
  const handledownright = () => {
    setimage(downright);
  };

  const handleAbout = () => {
    Navigate("/About");
  };

  const handleResume = () => {
    Navigate("/Resume");
  };

  const handleskills= ()=>{
    Navigate("/Skills")
  };

  const handleContact = ()=>{
    Navigate("/Contact")
  }

  const handleProjects = ()=>{
    Navigate("/Projects")
  }



  return (
    <div className="mainbox">
      <div className="upperdiv">
        <div
          className="text text-button"
          onMouseEnter={handleupleft}
          onMouseLeave={handleMouseLeave}
          onClick={handleAbout}
          style={{ cursor: "pointer" }}
        >
          ABOUT ME
        </div>
        <div
          className="text"
          onMouseEnter={handleupright}
          onMouseLeave={handleMouseLeave}
          onClick={handleskills}
          style={{ cursor: "pointer" }}
        >
          SKILL's
        </div>
      </div>

      <div className="middlediv">
        <img
          src={currentimage}
          alt="My Image"
          style={{ width: "200px", height: "auto", cursor: "pointer" }}
          onClick={handleResume}
        />
      </div>
      <h5 className="viewResume">VIEW RESUME</h5>
      <div className="lowerdiv">
        <div
          className="text"
          onMouseEnter={handledownleft}
          onMouseLeave={handleMouseLeave}
          style={{ cursor: "pointer" }}
          onClick={handleProjects}
        >
          PROJECT's
        </div>
        <div
          className="text"
          onMouseEnter={handledownright}
          onMouseLeave={handleMouseLeave}
          style={{ cursor: "pointer" }}
          onClick={handleContact}
        >
          CONTACT ME
        </div>
      </div>
    </div>
  );
}

export default Home;
