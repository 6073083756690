import React from "react";
import "../contact/Contact.css"; // Import the CSS file for styling
import profileImage from "../../images/download.png"; // Replace with your image path

// import React from "react";
// import "./ContactPage.css"; // Import the CSS file for styling
// import profileImage from "../../images/your-profile-image.jpg"; // Replace with your image path

function ContactPage() {
  return (
    <div className="contact-page">
      <header className="contact-header">
        <h1>Let's Connect!</h1>
        <p>Feel free to reach out through any of the following methods:</p>
      </header>
      
      <section className="profile-section">
        <img src={profileImage} alt="Harsh Mishra" className="profile-image" />
        <h2>Harsh Mishra</h2>
        <p>I'm a full-stack developer with a passion for creating dynamic and interactive applications. Let's get in touch!</p>
      </section>
      
      <section className="contact-info">
        <h3>Contact Details</h3>
        <div className="info-item">
          <span className="info-label">Email:</span>
          <a href="mailto:harsh.mishra@example.com">harshmishra9713@gmail.com</a>
        </div>
        <div className="info-item">
          <span className="info-label">Website:</span>
          <a href="harshscript.online" target="_blank" rel="noopener noreferrer">Portfolio Website</a>
        </div>
        <div className="info-item">
          <span className="info-label">Phone:</span>
          <span>+91-7089340506</span>
        </div>
      </section>

      <section className="social-media">
        <h3>Connect with Me</h3>
        <div className="social-links">
          <a href="https://www.linkedin.com/in/m-harsh" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="https://github.com/HARSHMISHRA0408" target="_blank" rel="noopener noreferrer">GitHub</a>
          {/* <a href="https://twitter.com/your-profile" target="_blank" rel="noopener noreferrer">Twitter</a> */}
          <a href="https://www.instagram.com/m.harsh_" target="_blank" rel="noopener noreferrer">Instagram</a>
          {/* <a href="https://facebook.com/your-profile" target="_blank" rel="noopener noreferrer">Facebook</a> */}
        </div>
      </section>

      <footer className="contact-footer">
        <p>Looking forward to connecting with you!</p>
        <p>© 2024 Harsh Mishra. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default ContactPage;
