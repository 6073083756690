import logo from './logo.svg';
import './App.css';
import Home from './Component/Home/Home.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import About from './Component/About/About.js';
import Skills from './Component/skills/Skills.js';
import Projects from './Component/projects/Projects.js';
import Contact from './Component/contact/Contact.js';
import Resume from './Component/Resume/Resume.js';

function App() {
  // return (
  //   <Home/>
  // );

 const router = createBrowserRouter([
  {
    path:"/",
    element:<Home/>
  },
  {
    path:"/about",
    element:<About/>
  },
  {
    path:"/skills",
    element:<Skills/>
  },
  {
    path:"/projects",
    element:<Projects/>
  },
  {
    path:"/contact",
    element:<Contact/>
  },
  {
    path:"/resume",
    element:<Resume/>
  },
 ])

 return (
  <RouterProvider router={router} />
);

}

export default App;
